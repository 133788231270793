import {createMachine, interpret} from 'xstate';

export const toggleMachine = createMachine({
  initial: 'hideNav',
  states: {
    hideNav: {
      on: {
        openNav: 'showNav',
      },
    },
    showNav: {
      on: {
        closeNav: 'hideNav',
      },
    },
  },
});

export const toggleService = interpret(toggleMachine).start();
