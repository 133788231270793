import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {AppContext} from '../../../context/AppContext';
import ImageEffect from '../../ImageEffect/ImageEffect';
import ShapeBlack from '../../../assets/images/Shape-Black-1.png';
import useWindowSize from '../../../hooks/useWindowSize';

import './Navigation.scss';

interface INavigation {
  page: string;
}

export function Navigation(props: INavigation) {
  const {page} = props;
  const {navData} = useContext(AppContext);
  const {width} = useWindowSize();
  const [availHeight, setAvailHeight] = useState(window.innerHeight);
  // const col = document.querySelector('.nav-col-1');
  // if (col) col.setAttribute('style', `height: ${window.innerHeight}px`);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (width > 1024) {
        setAvailHeight(window.innerHeight);
      }
    });
  }, [width, setAvailHeight]);

  return (
    <>
      <div
        className={`col-2 nav-col-1 ${page}`}
        style={{
          height: width > 1024 && availHeight,
        }}
      >
        {navData && (
          <>
            <div className="nav-texts">
              {navData.items
                .filter((nav) => nav.data.navType.iv.toLowerCase().includes('main'))
                .map((item: any, i: number) => (
                  <Link
                    to={`/${item.data.link.iv}`}
                    title={item.data.title.iv}
                    key={i}
                    // style={{height: document.getElementsByClassName('layout')[0].clientHeight / 2}}
                  >
                    <div
                      className={`texts animate-texts ${item.data.isWhite.iv === true ? 'fc-white' : 'fc-black'}`}
                      // style={{height: document.getElementsByClassName('layout')[0].clientHeight / 2}}
                    >
                      <div
                        className="block-top"
                        // style={{height: document.getElementsByClassName('layout')[0].clientHeight / 2}}
                      >
                        <div className="title-1 fw-bold tt-upper ">{item.data.title.iv}</div>
                        <div className="description" dangerouslySetInnerHTML={{__html: item.data.text.iv}} />
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
            <div className="sphere animate-sphere dol" style={{height: '100%'}}>
              {/* {width > 1024 && !isIpad ? (
                <ImageEffect src={ShapeBlack} />
              ) : ( */}
              <div className="ImageEffect">
                <img src={ShapeBlack} alt="" />
              </div>
              {/* )} */}
            </div>
            {navData.items
              .filter((nav) => nav.data.navType.iv.toLowerCase().includes('main'))
              .map((item: any, i: number) => (
                <div
                  className={`block block-${i + 1} animate-block`}
                  key={i}
                  style={{height: width > 1024 && availHeight / 2}}
                ></div>
              ))}
          </>
        )}
      </div>
    </>
  );
}
