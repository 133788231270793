import {createMachine, interpret} from 'xstate';

export const burgerMachine = createMachine({
  id: 'burgerTheme',
  initial: 'dark',
  states: {
    dark: {
      on: {
        LIGHT: 'light',
      },
    },
    light: {
      on: {
        DARK: 'dark',
      },
    },
  },
});

export const burgerService = interpret(burgerMachine).start();
