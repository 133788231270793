import React from 'react';
import {Link} from 'react-router-dom';
import {ASSETS_URL} from '../../config/config.json';
import ImageEffect from '../../components/ImageEffect/ImageEffect';

interface IItem {
  item: any;
  width: number;
  activeIndex: number;
  currentIndex: number;
}
export default function Slides(props: IItem) {
  const {item, width, activeIndex, currentIndex} = props;
  console.log(activeIndex + '=' + currentIndex);
  return (
    <div className={`section ${item.imagePosition === 'top' && width > 1024 ? ' section-group' : ''}`}>
      <div className="texts">
        <div className="tagline-2 tt-upper" dangerouslySetInnerHTML={{__html: item.title}} />
        <div className="title-1" dangerouslySetInnerHTML={{__html: item.text}} />
        {item.hasButton === true && (
          <Link className="btn" to={item.buttonUrl}>
            {item.buttonText}
          </Link>
        )}
      </div>
      {width < 1025 && (
        <div className="section-background">
          {item.imagePosition === 'right' ? (
            <>
              <img
                className="swiper-lazy"
                src={`${ASSETS_URL}/${item.image[0]}?width=600&height=600&cache=3600`}
                alt=""
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </>
          ) : (
            <>
              <img
                className="swiper-lazy"
                src={`${ASSETS_URL}/${item.image[3]}?width=600&height=600&cache=3600`}
                alt=""
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </>
          )}
        </div>
      )}
      {item.imagePosition === 'right' && width > 1025 && currentIndex === 0 && (
        <div className="section-background animated dol">
          <img
            alt=""
            className="swiper-lazy"
            src={`${ASSETS_URL}/${item.image[0]}?width=1000&height=1000&cache=3600`}
          />
          {/* <ImageEffect src={`${ASSETS_URL}/${item.image[0]}?width=1000&height=1000&cache=3600`} /> */}
        </div>
      )}
      {item.imagePosition === 'right' && width > 1025 && currentIndex !== 0 && (
        <div className="section-background animated">
          <img
            alt=""
            className="swiper-lazy"
            src={`${ASSETS_URL}/${item.image[0]}?width=1000&height=1000&cache=3600`}
          />
        </div>
      )}
      {item.imagePosition === 'top' && width > 1025 && (
        <div className="image-group">
          {item.image.map((image: any, z: number) => (
            <div className="section-image dol" key={z}>
              <img alt="" className="swiper-lazy" src={`${ASSETS_URL}/${image}?width=300&height=300&cache=3600`} />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
