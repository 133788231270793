import React, {useEffect, useState} from 'react';
import useFetch from '../hooks/useFetch';
import {API_URL} from '../config/config.json';

export interface AppContextProps {
  brandTheme: string;
  setBrandTheme: (brandTheme: string) => void;
  burgerTheme: string;
  setBurgerTheme: (brandTheme: string) => void;
  headerLineWidth: string;
  setHeaderLineWidth: (brandTheme: string) => void;
  navData: any;
  headerBackground: string;
  setHeaderBackground: (headerBackground: string) => void;
  animationClass: string;
  setAnimationClass: (animationEnds: string) => void;
}

interface AppContextProviderProps {
  children: any;
}

export const AppContext = React.createContext<AppContextProps>({
  brandTheme: 'dark',
  setBrandTheme: () => null,
  burgerTheme: 'dark',
  setBurgerTheme: () => null,
  headerLineWidth: '50vw',
  setHeaderLineWidth: () => null,
  navData: [],
  headerBackground: 'trans',
  setHeaderBackground: () => null,
  animationClass: 'loading-animation',
  setAnimationClass: () => null,
});

export const AppContextProvider: React.FC<AppContextProviderProps> = ({children}) => {
  const [brandTheme, setBrandTheme] = useState('dark');
  const [burgerTheme, setBurgerTheme] = useState('dark');
  const [headerLineWidth, setHeaderLineWidth] = useState('50vw');
  const [headerBackground, setHeaderBackground] = useState('trans');
  const [animationClass, setAnimationClass] = useState('loading-animation');
  const {data: navData} = useFetch(`${API_URL}/navigation/?$orderby=data/navOrder/iv%20asc`, 'navigation');

  return (
    <AppContext.Provider
      value={{
        brandTheme,
        setBrandTheme,
        burgerTheme,
        setBurgerTheme,
        headerLineWidth,
        setHeaderLineWidth,
        navData,
        headerBackground,
        setHeaderBackground,
        animationClass,
        setAnimationClass,
      }}
    >
      {brandTheme && burgerTheme && headerLineWidth && navData && headerBackground && animationClass && children}
    </AppContext.Provider>
  );
};

AppContext.displayName = 'AppContext';
