import React, {useContext, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import {API_URL, ASSETS_URL} from '../../config/config.json';
import {HelmetSeo} from '../../components/elements/HelmetSeo';
import {AppContext} from '../../context/AppContext';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react.js';
import {Controller, Keyboard, Lazy, Mousewheel, Pagination} from 'swiper';
import Slide from './Slide';
import {Footer} from '../../components/layout/Footer';

import 'swiper/swiper.min.css';
import './Project.scss';

export function Project() {
  const {slug} = useParams<{slug: string}>();
  const {setBrandTheme, setBurgerTheme, setHeaderLineWidth, setHeaderBackground} = useContext(AppContext);
  const {data: projectData} = useFetch(
    `${API_URL}/projects/?$filter=data/friendlyUrl/iv%20eq%20'${slug}'`,
    `project-${slug}`,
  );

  useEffect(() => {
    setHeaderLineWidth('100vw');
    setBrandTheme('dark');
    setBurgerTheme('dark');
    setHeaderBackground('trans');
  }, [projectData, setBrandTheme, setBurgerTheme, setHeaderBackground, setHeaderLineWidth]);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const hasSections = () => {
    if (projectData.items[0].data.sections.iv.filter((item: any) => item.isActive === true).length > 0) {
      return true;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 10);
  }, []);

  return (
    <>
      {projectData && (
        <>
          <HelmetSeo
            title={projectData.items[0].data.metaTitle.iv}
            description={projectData.items[0].data.metaDescription.iv}
          />
          <div className="project-container">
            <div className="sections-container">
              {projectData && (
                <>
                  <Swiper
                    speed={500}
                    autoHeight={false}
                    cssMode={false}
                    navigation={false}
                    pagination={hasSections() ? pagination : false}
                    mousewheel={true}
                    simulateTouch={true}
                    keyboard={true}
                    centeredSlides={true}
                    watchSlidesProgress={true}
                    lazy={true}
                    modules={[Lazy, Mousewheel, Keyboard, Pagination, Controller]}
                    className="aboutSwiper"
                  >
                    <>
                      <SwiperSlide>
                        <div className="section">
                          <div className="section-background dol">
                            <img
                              src={`${ASSETS_URL}/${projectData.items[0].data.mainImage.iv[0]}?cache=3600`}
                              alt={''}
                            />
                          </div>
                          <div className="texts inner-left">
                            <div className="subtitle-2 tt-upper fc-dark-grey">
                              {projectData.items[0].data.subtitle?.iv}
                            </div>
                            <div className="tagline-1 tt-upper">{projectData.items[0].data.title?.iv}</div>
                            <div
                              className="title-1"
                              dangerouslySetInnerHTML={{__html: projectData.items[0].data.mainText?.iv}}
                            />
                            {projectData.items[0].data.hasButton?.iv === true && (
                              <>
                                {projectData.items[0].data.buttonUrl.iv.includes('http') === true ? (
                                  <a
                                    className="btn"
                                    href={projectData.items[0].data.buttonUrl.iv}
                                    target="_blank"
                                    rel="noreferrer"
                                    title={projectData.items[0].data.buttonText.iv}
                                  >
                                    {projectData.items[0].data.buttonText.iv}
                                  </a>
                                ) : (
                                  <Link className="btn" to={projectData.items[0].data.buttonUrl.iv}>
                                    {projectData.items[0].data.buttonText.iv}
                                  </Link>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                      {projectData.items[0].data.sections.iv
                        .filter((item: any) => item.isActive === true)
                        .map((item: any, i: number) => (
                          <SwiperSlide key={i}>
                            <Slide item={item} />
                          </SwiperSlide>
                        ))}
                    </>
                  </Swiper>
                </>
              )}
            </div>

            <Link to={`/showcase`}>
              <div className="back-icon">
                <div className="left-icon">
                  <svg>
                    <use xlinkHref="#left-arrow" />
                  </svg>
                </div>
                <div className="back-text">Back to Showcase</div>
              </div>
            </Link>
          </div>
          {projectData.items[0].data.sections.iv.filter((item: any) => item.isActive === true).length > 0 && (
            <Footer footerType="scrollIcon" />
          )}
        </>
      )}
    </>
  );
}
