import React, {useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import {API_URL} from '../../config/config.json';
import {Navigation} from '../../components/layout/MobileNav/Navigation';
import {HelmetSeo} from '../../components/elements/HelmetSeo';
import {AppContext} from '../../context/AppContext';
import useWindowSize from '../../hooks/useWindowSize';

import './Home.scss';

export function Home() {
  const {data: homeData} = useFetch(`${API_URL}/home`, 'home');
  const {width} = useWindowSize();

  const {setBrandTheme, setBurgerTheme, setHeaderLineWidth, setHeaderBackground} = useContext(AppContext);

  useEffect(() => {
    setBrandTheme('dark');
    setBurgerTheme('dark');
    setHeaderBackground('trans');

    if (width > 1081) {
      setHeaderLineWidth('50vw');
    } else {
      setHeaderLineWidth('100vw');
    }
  }, [setBrandTheme, setBurgerTheme, setHeaderBackground, setHeaderLineWidth, width]);

  useEffect(() => {}, []);

  return (
    <div className="home-container">
      {homeData && (
        <>
          <HelmetSeo
            title={homeData.items[0].data.metaTitle.iv}
            description={homeData.items[0].data.metaDescription.iv}
          />
          <div className="col-1 animate-left-texts">
            <div className="title-1 ">{homeData.items[0].data.subtitle.iv}</div>
            <div
              className="tagline-1 "
              dangerouslySetInnerHTML={{
                __html: homeData.items[0].data.title.iv,
              }}
            />
            <div
              className="title-1 "
              dangerouslySetInnerHTML={{
                __html: homeData.items[0].data.text.iv,
              }}
            />
            <Link className="btn btn-primary " to={homeData.items[0].data.buttonLink.iv}>
              {homeData.items[0].data.buttonText.iv}
            </Link>
          </div>
          <Navigation page="home-inner" />
        </>
      )}
    </div>
  );
}
