import React from 'react';
import {Helmet} from 'react-helmet-async';

interface IHelmetSeo {
  title?: string;
  description?: string;
}

export function HelmetSeo(props: IHelmetSeo) {
  const {title, description} = props;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    </>
  );
}
