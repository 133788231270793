import React from 'react';
import sphere from '../../../assets/images/loading-sphere.png';
import ImageEffect from '../../ImageEffect/ImageEffect';
import './Loader.scss';

interface ILoader {
  percent?: string;
  show: boolean;
}

export function Loader(props: ILoader) {
  const {percent, show} = props;

  return (
    <div className={`loader-container ${show ? 'show' : 'isHidden'}`}>
      <div className="loader-inner">
        <div className="loading-logo">
          <svg>
            <use xlinkHref="#loading-logo" />
          </svg>
        </div>
        <div className="sphere">
          <img alt="" src={sphere} />
        </div>
        <div className="percent-loaded">Loading... {percent}</div>
      </div>
    </div>
  );
}
