import React, {useContext, useEffect, useState} from 'react';
import useFetch from '../../hooks/useFetch';
import {API_URL} from '../../config/config.json';
import {HelmetSeo} from '../../components/elements/HelmetSeo';
import {AppContext} from '../../context/AppContext';
import useWindowSize from '../../hooks/useWindowSize';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react.js';
import {Controller, Keyboard, Lazy, Mousewheel, Pagination} from 'swiper';
import Slide from './Slide';
import {Footer} from '../../components/layout/Footer';

import 'swiper/swiper.min.css';
import './About.scss';

export function About() {
  const {data: aboutData} = useFetch(`${API_URL}/about`, 'about');
  const {width} = useWindowSize();
  const {setBrandTheme, setBurgerTheme, setHeaderLineWidth, setHeaderBackground} = useContext(AppContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  useEffect(() => {
    setHeaderLineWidth('100vw');
    setBrandTheme('dark');
    setBurgerTheme('dark');
    setHeaderBackground('trans');
    window.scrollTo(0, 10);
  }, [setBrandTheme, setBurgerTheme, setHeaderBackground, setHeaderLineWidth]);

  return (
    <>
      {aboutData && (
        <>
          <HelmetSeo
            title={aboutData.items[0].data.metaTitle.iv}
            description={aboutData.items[0].data.metaDescription.iv}
          />
          <div className="about-container">
            <div className="sections-container ">
              <Swiper
                speed={500}
                autoHeight={false}
                cssMode={false}
                navigation={false}
                pagination={pagination}
                mousewheel={true}
                simulateTouch={true}
                keyboard={true}
                centeredSlides={true}
                watchSlidesProgress={true}
                preloadImages={false}
                lazy={true}
                modules={[Lazy, Mousewheel, Keyboard, Pagination, Controller]}
                className="aboutSwiper"
                onSlideChange={(index) => setActiveIndex(index.activeIndex)}
                // onIndexChanged={(index: number) => setCurrentIndex(index)}
              >
                {aboutData.items[0].data.sections.iv && (
                  <>
                    {aboutData.items[0].data.sections.iv.map((item: any, i: number) => (
                      <SwiperSlide key={i} className="inner">
                        <Slide item={item} width={width} currentIndex={i} activeIndex={activeIndex} />
                      </SwiperSlide>
                    ))}
                  </>
                )}
              </Swiper>
            </div>
            <Footer footerType="scrollIcon" />
          </div>
        </>
      )}
    </>
  );
}
