import React, {Suspense} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import CacheBuster from 'react-cache-buster';
import {AppContextProvider} from '../context/AppContext';
import {Container} from '../components/layout/Container';
import {version} from '../../package.json';
import {isProduction, basename} from '../config/config.json';
import {ReactComponent as UISvg} from '../assets/ui/ui.svg';
import ScrollToTop from '../utils/scrollToTop';
import {Intro} from './Intro';
import {About} from './About';
import {Showcase} from './Showcase';
import {Project} from './Project';
import {Contact} from './Contact';
import {Content} from './Content';
import {Glimpse} from './Glimpse';
import {Home} from './Home';
import {Loader} from '../components/elements/Loader';

import '../styles/main.scss';

function App() {
  return (
    <HelmetProvider>
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        isVerboseMode={false}
        loadingComponent={<Loader show={true} />}
      >
        <BrowserRouter basename={basename}>
          <ScrollToTop />
          <Suspense fallback={<Loader show={true} />}>
            <AppContextProvider>
              <Container>
                <Switch>
                  <Route exact path={`/`} component={Home} />
                  <Route exact path={`/intro`} component={Intro} />
                  <Route exact path={`/about`} component={About} />
                  <Route exact path={`/showcase`} component={Showcase} />
                  <Route exact path={`/project/:slug`} component={Project} />
                  <Route exact path={`/contact`} component={Contact} />
                  <Route exact path={`/glimpse`} component={Glimpse} />
                  <Route exact path={`/page/:slug`} component={Content} />
                </Switch>
              </Container>
            </AppContextProvider>
          </Suspense>
          <UISvg />
        </BrowserRouter>
      </CacheBuster>
    </HelmetProvider>
  );
}

export default App;
