import React from 'react';
import useWindowSize from '../../../hooks/useWindowSize';

import './ScrollIcon.scss';

export function ScrollIcon() {
  const {width, height} = useWindowSize();

  return (
    <div className="scrollContainer">
      <div className={`scroll-icon ${width <= 1366 && height <= 1024 && 'isMobile'}`}>
        <div className="scroll-icon__wheel-outer">
          <div className="scroll-icon__wheel-inner"></div>
        </div>
      </div>
      {width <= 1366 && height <= 1024 ? (
        <div className="scrollText">Swipe to navigate</div>
      ) : (
        <div className="scrollText">
          Scroll or use <span style={{fontSize: '1.1rem'}}>&larr; &rarr;</span> arrows to navigate
        </div>
      )}
    </div>
  );
}
