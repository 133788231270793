import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {toggleService} from '../machines/toggleMachine';
import {burgerService} from '../machines/burgerMachine';
import {brandService} from '../machines/brandMachine';

export default function ScrollToTop() {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    toggleService.send('closeNav');
    const d2 = document.querySelector('.burger-menu');
    const d3 = document.querySelector('.brand');
    if (d2) burgerService.send('DARK');
    if (d3) brandService.send('DARK');
  }, [pathname]);

  return null;
}
