import React, {useContext} from 'react';
import {Navigation} from './Navigation';
import {Link, useLocation} from 'react-router-dom';
import {AppContext} from '../../../context/AppContext';

import './MobileNav.scss';

interface IMobileNav {
  status?: string;
}

export function MobileNav(props: IMobileNav) {
  const {status} = props;
  const location = useLocation();
  const {navData} = useContext(AppContext);

  const getYear = () => {
    return new Date().getFullYear();
  };
  const mobileNav = document.querySelector('.mobile-nav');
  if (mobileNav) mobileNav.setAttribute('style', `height: ${window.innerHeight}px`);

  return (
    <div className={`mobile-nav ${status}`}>
      <>
        {location.pathname !== '/' ? <Navigation page="" /> : <Navigation page="home" />}
        <div className="flex-col-50 col-1 nav-col-2">
          {navData && (
            <div className="nav-col-2-footer-links">
              {navData.items
                .filter((nav) => nav.data.navType.iv.toLowerCase().includes('legal'))
                .map((item: any, i: number) => (
                  <div className="nav-link callout-1 tt-upper" key={i}>
                    <Link to={`/page/${item.data.link.iv}`} title={item.data.title.iv}>
                      {item.data.title.iv}
                    </Link>
                  </div>
                ))}
            </div>
          )}
          <div className="nav-col-2-footer-copywrite callout-1 fc-dark-grey">
            Copyright &copy; Greymatter {getYear()}
          </div>
        </div>
      </>
    </div>
  );
}
