import React, {useContext, useEffect} from 'react';
import useFetch from '../../hooks/useFetch';
import {API_URL} from '../../config/config.json';
import {useParams} from 'react-router-dom';
import {HelmetSeo} from '../../components/elements/HelmetSeo';
import {AppContext} from '../../context/AppContext';

import './Content.scss';

export function Content() {
  const {slug} = useParams<{slug: string}>();

  const {setBrandTheme, setBurgerTheme, setHeaderLineWidth, setHeaderBackground} = useContext(AppContext);
  const {data} = useFetch(`${API_URL}/navigation/?$filter=data/link/iv%20eq%20'${slug}'`, `page-${slug}`);
  useEffect(() => {
    setBrandTheme('dark');
    setBurgerTheme('dark');
    setHeaderLineWidth('100vw');
    setHeaderBackground('withBg');
  }, [setBrandTheme, setBurgerTheme, setHeaderBackground, setHeaderLineWidth]);

  return (
    <div className="content-container">
      {data && (
        <>
          <HelmetSeo title={data.items[0].data.title.iv} description={data.items[0].data.title.iv} />
          <div className="content-inner">
            <div className="tagline-2 tt-upper">{data.items[0].data.title.iv}</div>
            <div className="title-1" dangerouslySetInnerHTML={{__html: data.items[0].data.text.iv}} />
          </div>
        </>
      )}
    </div>
  );
}
