import React, {useEffect, useRef, useState} from 'react';
import Texture6 from '../assets/images/showcase/AU_FG_Texture-6.jpg';
import Texture2 from '../assets/images/showcase/AU_FG_Texture-2.jpg';
import VioletRed from '../assets/images/showcase/13_Pale_Violet_Red.jpg';
import Image35 from '../assets/images/showcase/35.jpg';
import Texture61 from '../assets/images/showcase/mask6-1.png';
import Image54 from '../assets/images/showcase/5417937.jpg';

interface ILazyImage {
  className?: string;
  src: string;
  alt?: string;
  mask?: string;
}

export function LazyImage(props: ILazyImage) {
  const {className, src, alt, mask} = props;
  const [isLoading, setIsLoading] = useState(true);
  const imgRef = useRef<any | null>(null);
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setIsLoading(false);
    }
  }, [setIsLoading, isLoading]);
  const setBG = (mask: string, image: string) => {
    let background = '';
    let backgroundBlendMode = '';
    if (mask === 'mask-1') {
      background = `url(${Texture2}), url(${image}), url(${Image35})`;
      backgroundBlendMode = 'exclusion, screen, normal';
    }
    if (mask === 'mask-2') {
      background = `url(${Texture6}), url(${image}), #F8F5EF`;
      backgroundBlendMode = 'difference, multiply, normal';
    }
    if (mask === 'mask-3') {
      background = `url(${Texture6}), url(${image}), url(${VioletRed})`;
      backgroundBlendMode = 'exclusion, screen, normal';
    }
    if (mask === 'mask-4') {
      background = `url(${Texture6}), url(${image}), url(${Image54})`;
      backgroundBlendMode = 'color-dodge, normal, normal';
    }
    if (mask === 'mask-5') {
      background = `url(${Texture6}), url(${image}), linear-gradient(218.31deg, #68A4DC 0%, #47B87B 100%)`;
      backgroundBlendMode = 'difference, normal, normal';
    }
    if (mask === 'mask-6') {
      background = `url(${Texture61}), url(${Image35}),  url(${image})`;
      backgroundBlendMode = 'normal, difference, normal';
    }
    if (!mask) {
      background = `url(${image})`;
      backgroundBlendMode = 'normal';
    }
    return {background, backgroundBlendMode};
  };

  return (
    <div
      className={`image-wrapper `}
      style={{
        background: `${setBG(mask, src).background}`,
        backgroundBlendMode: `${setBG(mask, src).backgroundBlendMode}`,
        backgroundSize: 'cover',
      }}
    >
      <img
        loading="lazy"
        src={`${src}`}
        alt={alt}
        ref={imgRef}
        onLoad={() => setIsLoading(false)}
        className={className}
        style={{width: '100%', opacity: 0}}
      />
    </div>
  );
}
