import {createMachine, interpret} from 'xstate';

export const brandMachine = createMachine({
  id: 'brandTheme',
  initial: 'dark',
  states: {
    dark: {
      on: {
        LIGHT: 'light',
      },
    },
    light: {
      on: {
        DARK: 'dark',
      },
    },
  },
});

export const brandService = interpret(brandMachine).start();
