import {useEffect, useState} from 'react';
import axios from 'axios';

const useFetch = (url: string, id: string) => {
  const [data, setData] = useState<any>();
  // const [loading, setLoading] = useState(false);
  const storedData = sessionStorage.getItem('cachedData');
  const cachedData = storedData ? JSON.parse(storedData) : [];
  const urlData = cachedData.find((item: any) => item.id === id);

  useEffect(() => {
    let urlExists = false;
    let dataIsValid = false;

    if (urlData) {
      if (urlData.url === url) {
        urlExists = true;
      }

      if (+new Date() - urlData.timeStamp < 10 * 60000) {
        dataIsValid = true;
      } else {
        dataIsValid = false;
      }
    }

    if (urlExists === false || dataIsValid === false) {
      const fetchData = async () => {
        try {
          const {data: response} = await axios.get(url, {
            headers: {'Cache-Control': 'max-age=3600'},
          });
          setData(response);
          //sessionStorage.setItem('cachedData', JSON.stringify([...cachedData.filter((d: any) => d.id !== id),{id: id, url: url, timeStamp: +new Date(), data: response}]))
        } catch (error) {
          console.error(error);
        }
        // setLoading(false);
      };

      fetchData();
    } else {
      setData(urlData.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    // loading,
  };
};

export default useFetch;
