import {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {AppContext} from '../../../context/AppContext';
import {toggleService} from '../../../machines/toggleMachine';
import {MobileNav} from '../MobileNav';
import './Header.scss';

export function Header() {
  const [mobileNav, setMobileNav] = useState<string>('');
  const location = useLocation();
  const {brandTheme, burgerTheme, headerLineWidth, headerBackground} = useContext(AppContext);

  useEffect(() => {
    toggleService.subscribe((status) => {
      setMobileNav(status.value.toString());
    });
  }, []);

  const navLinks = document.querySelector('.brand-link');
  document.addEventListener('click', (event: any) => {
    if (navLinks !== null && navLinks.contains(event.target)) {
      toggleService.send('closeNav');
    }
  });

  return (
    <>
      <header className={`${mobileNav} ${headerBackground}`}>
        <div className="container">
          <div className={`brand ${brandTheme}`}>
            <Link to="/" title="Greymatter Software" className="brand-link">
              <svg>
                <use xlinkHref="#logo" />
              </svg>
            </Link>
          </div>
          {location.pathname !== '/' && (
            <div
              id="burger"
              className={`burger-menu${mobileNav === 'showNav' ? ' isOpened' : ''} ${burgerTheme}`}
              onClick={() => {
                toggleService.state.value === 'hideNav'
                  ? toggleService.send('openNav')
                  : toggleService.send('closeNav');
              }}
            >
              <div></div>
            </div>
          )}
        </div>
        {location.pathname !== '/' && <MobileNav status={mobileNav} />}
      </header>
      <div className="header-line" style={{width: headerLineWidth}}></div>
    </>
  );
}
