import React from 'react';
import {Link} from 'react-router-dom';
import {ASSETS_URL} from '../../config/config.json';

interface IItem {
  item: any;
}
export default function Slides(props: IItem) {
  const {item} = props;
  return (
    <div className={`section`}>
      <div className="section-background dol">
        <img src={`${ASSETS_URL}/${item.image[0]}?cache=3600`} alt="" />
      </div>
      <div className="texts inner-left">
        <div className="tagline-2 tt-upper" dangerouslySetInnerHTML={{__html: item.title}} />
        <div className="title-1" dangerouslySetInnerHTML={{__html: item.text}} />
        {item.hasButton === true && (
          <>
            {item.buttonUrl.includes('http') === true ? (
              <a className="btn" href={item.buttonUrl} target="_blank" rel="noreferrer" title={item.buttonText}>
                {item.buttonText}
              </a>
            ) : (
              <Link className="btn" to={item.buttonUrl}>
                {item.buttonText}
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
}
