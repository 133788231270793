import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {AppContext} from '../../../context/AppContext';
import {ScrollIcon} from '../../elements/ScrollIcon';
import './Footer.scss';

interface IFooter {
  footerType: 'footerLinks' | 'scrollIcon';
}

export function Footer(props: IFooter) {
  const {footerType} = props;
  const {navData} = useContext(AppContext);

  return (
    <footer>
      <div className="inner">
        {footerType === 'footerLinks' && (
          <div className="footer-links">
            {navData && (
              <>
                {navData.items
                  .filter((nav) => nav.data.navType.iv.toLowerCase().includes('main'))
                  .map((item: any, i: number) => (
                    <div className="footer-link tt-upper callout-1" key={i}>
                      <Link to={item.data.link.iv}>{item.data.title.iv}</Link>
                    </div>
                  ))}
              </>
            )}
          </div>
        )}
        {footerType === 'scrollIcon' && <ScrollIcon />}
      </div>
    </footer>
  );
}
