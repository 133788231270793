import React, {useState} from 'react';
import {Formik, Form, Field} from 'formik';
import axios from 'axios';
import {API_URL, authUrl, clientID, clientSecret} from '../../config/config.json';
import './ContactForm.scss';

interface IContactForm {
  handleIsOpened: any;
}

export function ContactForm(props: IContactForm) {
  const {handleIsOpened} = props;
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const handleValidation = (values: any) => {
    let errors: any = {};

    for (const key in values) {
      const value = `${values[key]}`;

      if (!value && key !== 'website') {
        errors[key] = 'This field is required';
      }
    }
    return errors;
  };
  const onSubmit = (values: any, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
    const url = `${API_URL}/contactform/`;
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('client_id', clientID);
    params.append('client_secret', clientSecret);
    params.append('scope', 'squidex-api');

    axios({
      method: 'post',
      url: authUrl,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
      },
      data: params,
    })
      .then((response) => {
        const token = response.data.access_token;
        axios
          .post(url, values, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            setSubmitting(false);
            if (response) {
              resetForm();
              setIsFormSuccess(true);
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="close" onClick={() => handleIsOpened()}>
        <div></div>
      </div>
      <div className="contact-form-content">
        {isFormSuccess && (
          <div className="contact-form-success">
            <div className="text">Your message has been successfully sent!</div>
          </div>
        )}

        <Formik
          initialValues={{name: '', email: '', message: ''}}
          validate={(values) => {
            return handleValidation(values);
          }}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            onSubmit(values, setSubmitting, resetForm);
          }}
        >
          {({errors, touched, handleChange, isSubmitting}) => (
            <Form id="contact-form" className="form -pt2">
              <div className="form-fields">
                <div className="form-row --flex-row gap-2 ">
                  <div className={`form-control ${touched.name && errors.name ? 'has-error' : ''}`}>
                    <Field type="text" name="name['iv']" id="name" placeholder="Name" />
                    <label htmlFor="name">Name</label>
                    {touched.name && errors.name && <p className="error-message">{errors.name}</p>}
                  </div>

                  <div className={`form-control ${touched.email && errors.email ? 'has-error' : ''}`}>
                    <Field type="email" name="email['iv']" id="email" placeholder="Email" />
                    <label htmlFor="email">EMAIL</label>
                    {touched.email && errors.email && <p className="error-message">{errors.email}</p>}
                  </div>
                </div>

                <div className="form-row form-row-message -pt2">
                  <div className={`form-control ${touched.message && errors.message ? 'has-error' : ''}`}>
                    <Field as="textarea" name="message['iv']" id="message" />
                    <label htmlFor="message">Your message</label>
                    {touched.message && errors.message && <p className="error-message --textarea">{errors.message}</p>}
                  </div>
                </div>
              </div>

              <div className="form-action">
                <button className="btn btn-white" type="submit" disabled={isSubmitting}>
                  <span className="text">Submit</span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
