import React, {useContext, useEffect, useRef, useState} from 'react';
import useFetch from '../../hooks/useFetch';
import {API_URL} from '../../config/config.json';
import {HelmetSeo} from '../../components/elements/HelmetSeo';
import useWindowSize from '../../hooks/useWindowSize';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {AppContext} from '../../context/AppContext';
import {ContactForm} from './';
import ImageEffect from '../../components/ImageEffect/ImageEffect';
import contactShape from '../../assets/images/contact-shape.png';

import './Contact.scss';
import {Footer} from '../../components/layout/Footer';

export function Contact() {
  const {data: contactData} = useFetch(`${API_URL}/contact`, 'contact');
  const [isOpened, setIsOpened] = useState(false);
  const {width} = useWindowSize();
  const {setBrandTheme, setBurgerTheme, setHeaderLineWidth, setHeaderBackground} = useContext(AppContext);
  const ref = useRef(null);

  useEffect(() => {
    setHeaderLineWidth('100vw');
    setBrandTheme('light');
    setBurgerTheme('light');
    setHeaderBackground('trans');
  }, [setBrandTheme, setBurgerTheme, setHeaderBackground, setHeaderLineWidth]);

  const handleIsOpened = () => {
    setIsOpened(!isOpened);
  };

  useEffect(() => {
    const listener = (e: any) => {
      if (e.key === 'Escape' && isOpened === true) {
        setIsOpened(false);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [isOpened]);

  const handleClickOutside = () => {
    if (isOpened) {
      setIsOpened(false);
    }
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <>
      {contactData && (
        <>
          <HelmetSeo
            title={contactData.items[0].data.metaTitle.iv}
            description={contactData.items[0].data.metaDescription.iv}
          />
          <div className="contact-container">
            <>
              <div className="contact-inner">
                <div className="section-background">
                  {/* {width > 1024 ? <ImageEffect src={contactShape} /> : <img src={contactShape} alt="" />} */}
                  <img src={contactShape} alt="" />
                </div>

                <div className="texts">
                  <div
                    className="tagline-2 tt-upper"
                    dangerouslySetInnerHTML={{
                      __html: contactData.items[0].data.title.iv,
                    }}
                  />
                  <div
                    className="subtitle-2"
                    dangerouslySetInnerHTML={{
                      __html: contactData.items[0].data.text.iv,
                    }}
                  />
                  <div className={`btn btn-white ${isOpened && 'isOpened'}`} onClick={() => setIsOpened(!isOpened)}>
                    {contactData.items[0].data.buttonText.iv}
                  </div>
                  <div className="info">
                    <div className="contact-icon">
                      <svg>
                        <use xlinkHref="#address" />
                      </svg>
                    </div>
                    <div
                      className="subtitle-2 contact-info"
                      dangerouslySetInnerHTML={{__html: contactData.items[0].data.address.iv}}
                    />
                  </div>
                  <div className="info">
                    <div className="contact-icon">
                      <svg>
                        <use xlinkHref="#call" />
                      </svg>
                    </div>
                    <div
                      className="subtitle-2 contact-info"
                      dangerouslySetInnerHTML={{__html: contactData.items[0].data.phone.iv}}
                    />
                  </div>
                  <div className="info">
                    <div className="contact-icon">
                      <svg>
                        <use xlinkHref="#mail" />
                      </svg>
                    </div>
                    <div
                      className="subtitle-2 contact-info"
                      dangerouslySetInnerHTML={{__html: contactData.items[0].data.email.iv}}
                    />
                  </div>
                </div>
              </div>
            </>
            {/* </div> */}
            <Footer footerType="footerLinks" />
          </div>
        </>
      )}
      <div className={`contact-form ${isOpened && 'isOpened'}`} ref={ref}>
        <ContactForm handleIsOpened={handleIsOpened} />
      </div>
    </>
  );
}
