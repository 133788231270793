import React, {useContext, useEffect, useRef, useState} from 'react';
import useFetch from '../../hooks/useFetch';
import {API_URL, ASSETS_URL} from '../../config/config.json';
import {Link} from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import {LazyImage} from '../../lib/LazyImage';
import {HelmetSeo} from '../../components/elements/HelmetSeo';
import {AppContext} from '../../context/AppContext';

import './Showcase.scss';

export function Showcase() {
  const {setBrandTheme, setBurgerTheme, setHeaderLineWidth, setHeaderBackground} = useContext(AppContext);

  const {data: showcaseData} = useFetch(`${API_URL}/showcase`, 'showcase');
  const {data: dataProjectsA} = useFetch(
    `${API_URL}/projects/?$filter=data/isActive/iv eq true&$orderby=data/projectOrder/iv asc&$top=6`,
    'showcase-page-1',
  );
  const {data: dataProjectsB} = useFetch(
    `${API_URL}/projects/?$filter=data/isActive/iv eq true&$orderby=data/projectOrder/iv%20asc&$skip=6`,
    'showcase-page-2',
  );

  const {width} = useWindowSize();
  const [imageIsloaded, setImageIsLoaded] = useState(false);
  const imgRef = useRef<any | null>(null);
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setImageIsLoaded(true);
    }
  }, [setImageIsLoaded, imageIsloaded]);

  useEffect(() => {
    setBrandTheme('dark');
    setBurgerTheme('dark');
    setHeaderBackground('trans');
    if (width > 1080) {
      setHeaderLineWidth('50vw');
    } else {
      setHeaderLineWidth('100vw');
    }
  }, [setBrandTheme, setBurgerTheme, setHeaderBackground, setHeaderLineWidth, width]);

  return (
    <>
      {showcaseData && (
        <>
          <div className="showcase-container">
            {dataProjectsA && showcaseData && (
              <>
                <HelmetSeo
                  title={showcaseData.items[0].data.metaTitle.iv}
                  description={showcaseData.items[0].data.metaDescription.iv}
                />
                <div className="sections-container">
                  <div className={`section section-1`}>
                    <div
                      className="main-item dol"
                      style={{
                        backgroundImage: `url(${ASSETS_URL}/${showcaseData.items[0].data.background.iv[0]}?width=200&cache=3600)`,
                      }}
                    >
                      <div className="texts">
                        <div className="subtitle tt-upper">{showcaseData.items[0].data.title1.iv}</div>
                        <div className="tagline-2 tt-upper">{showcaseData.items[0].data.title2.iv}</div>
                        <div
                          className="subtitle tt-upper"
                          dangerouslySetInnerHTML={{__html: showcaseData.items[0].data.text.iv}}
                        />
                      </div>
                    </div>
                    {dataProjectsA && (
                      <>
                        {dataProjectsA.items.map((item: any, i: number) => (
                          <div className={`item dol`} key={i}>
                            <Link to={`project/${item.data.friendlyUrl.iv}`}>
                              <div className={`item-image ${item.data.indexMask.iv}`}>
                                <LazyImage
                                  src={`${ASSETS_URL}/${item.data.indexImage.iv[0]}?cache=3600`}
                                  alt={item.data.title.iv}
                                  mask={item.data.indexMask.iv}
                                />
                              </div>
                              <div className="title-2">{item.data.title.iv}</div>
                            </Link>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  {dataProjectsB && dataProjectsB.items.length > 0 && (
                    <div className="panel section section-2 panel" id="panel-2">
                      <>
                        {dataProjectsB.items.map((item: any, i: number) => (
                          <div className={`item`} key={`a-${i}`}>
                            <Link to={`project/${item.data.friendlyUrl.iv}`}>
                              <div className="item-image">
                                <LazyImage
                                  src={`${ASSETS_URL}/${item.data.indexImage.iv[0]}`}
                                  alt={item.data.title.iv}
                                  mask={item.data.indexMask.iv}
                                />
                              </div>
                              <div className="title-2">{item.data.title.iv}</div>
                            </Link>
                          </div>
                        ))}
                      </>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {/* {width > 1085 && (
        <div className="sections-container-hidden">
          {Array.from(Array(scrollProperties.totalSections).keys()).map((i) => (
            <div className={`hidden-section`} key={`b-${i + 1}`}></div>
          ))}
        </div>
      )} */}
        </>
      )}
    </>
  );
}
