import {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../context/AppContext';
import {toggleService} from '../../../machines/toggleMachine';
import {Header} from '../Header';
import {useLocation} from 'react-router-dom';
import img1 from '../../../assets/images/Background_1.png';
import img2 from '../../../assets/images/Background_2.png';
import img3 from '../../../assets/images/Background_3.png';
import img4 from '../../../assets/images/Background_4.png';
import img5 from '../../../assets/images/background-about.jpg';
import img6 from '../../../assets/images/background-white.jpg';
import img7 from '../../../assets/images/contact-bg-line.png';
import img8 from '../../../assets/images/contact-bg.jpg';
import img9 from '../../../assets/images/Shape-Black-1.png';
import img10 from '../../../assets/images/texture-2.jpg';
import img11 from '../../../assets/images/showcase/13_Pale_Violet_Red.jpg';
import img12 from '../../../assets/images/showcase/35.jpg';
import img13 from '../../../assets/images/showcase/AU_FG_Texture-2.jpg';
import img14 from '../../../assets/images/showcase/AU_FG_Texture-6.jpg';
import img15 from '../../../assets/images/showcase/mask6-1.png';
import img16 from '../../../assets/images/contact-shape.png';
import img17 from '../../../assets/images/loading-sphere.png';
import img18 from '../../../assets/images/loader-bg.jpg';

import useWindowSize from '../../../hooks/useWindowSize';
import {Loader} from '../../elements/Loader';

import './Container.scss';

interface IContainerProps {
  children: any;
}

export function Container(props: IContainerProps) {
  const {children} = props;
  const {animationClass, setAnimationClass} = useContext(AppContext);
  const [mobileNav, setMobileNav] = useState<string>();
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const [countImageLoaded, setCountImageLoaded] = useState(0);
  const [percent, setPercent] = useState('0%');
  const [page, setPage] = useState<string>('');
  const {pathname} = useLocation();
  const {width} = useWindowSize();
  const {setHeaderLineWidth} = useContext(AppContext);
  const imgs = [
    img18,
    img17,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
  ];
  const animation = sessionStorage.getItem('animation');
  const animationData = animation ? JSON.parse(animation) : 0;

  useEffect(() => {
    toggleService.subscribe((status) => {
      setMobileNav(status.value.toString());
    });
  }, [setMobileNav]);

  useEffect(() => {
    const pg = pathname.split('/');
    if (pg[1] !== '') {
      setPage(pg[1]);
    } else {
      setPage('home');
    }
  }, [pathname]);

  useEffect(() => {
    if (width < 1080) {
      window.scrollTo(0, 5);
    }
  }, [width]);

  useEffect(() => {
    const layout = document.querySelector('.layout');
    if (animationData === 1) {
      layout.classList.remove('loading-animation');
      setImageIsLoaded(true);
      setAnimationClass(' ');
    } else if (animationData === 0 || !animationData) {
      layout.classList.add('loading-animation');
      const val = Math.ceil(countImageLoaded * 100) / imgs.length;
      setPercent(`${val.toFixed()}%`);
      setHeaderLineWidth('0vw');
      if (countImageLoaded === imgs.length) {
        setImageIsLoaded(true);
        setHeaderLineWidth('50vw');
        setTimeout(function () {
          sessionStorage.setItem('animation', '1');
          layout.classList.remove('loading-animation');
          setAnimationClass(' ');
        }, 7000);
      }
    }
  }, [animationData, countImageLoaded, imgs.length, setAnimationClass, setHeaderLineWidth]);

  useEffect(() => {
    if (animationData === 1) {
      setAnimationClass(' ');
    } else {
      setAnimationClass('loading-animation');
    }
  }, [animationData, setAnimationClass]);

  const rootElement = document.querySelector('#root');
  rootElement.setAttribute('style', `min-height: ${window.innerHeight}px`);
  // rootElement.setAttribute('style', `height: 100vh`);

  return (
    <>
      <Loader percent={percent} show={!imageIsLoaded} />

      <div className={`layout ${mobileNav} page-${page} ${animationClass} `} style={{opacity: imageIsLoaded ? 1 : 0}}>
        <Header />
        {children}
      </div>
      {imgs.map((img, i) => (
        <img
          onLoad={() => setCountImageLoaded(countImageLoaded + 1)}
          src={img}
          alt=""
          style={{display: 'none'}}
          key={i}
        />
      ))}
    </>
  );
}
